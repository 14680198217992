<template>
  <v-col class="ma-3">
    <v-form
      ref="form"
      v-model="isValid"
      @submit.prevent="handleRegister()"
    >
      <h1 class="text-h1 white--text mb-12">
        {{ $t('authorisation.passwordReset.title') }}
      </h1>
      <v-alert
        :type="alertType"
        :value="!!alertMessage.length"
        class="mb-10"
        transition="fade-transition"
      >
        {{ alertMessage }}
      </v-alert>
      <k-field-group language-prefix="authorisation.fields">
        <KTextField
          v-model="form.email"
          field="email"
          tabindex="1"
        />
        <KTextField
          v-model="form.password"
          :append-icon="showPassword ? '$invisible' : '$visible'"
          :type="showPassword ? 'text' : 'password'"
          field="password"
          tabindex="2"
          :hint="passwordHint"
          @click:append="showPassword = !showPassword"
        />
        <KTextField
          v-model="form.passwordConfirmation"
          :append-icon="showPassword ? '$invisible' : '$visible'"
          :type="showPassword ? 'text' : 'password'"
          field="password"
          tabindex="3"
          :hint="passwordConfirmationHint"
          @click:append="showPassword = !showPassword"
        />
      </k-field-group>
      <v-row
        class="no-gutters my-6"
        justify="end"
      >
        <VBtn
          :loading="isLoading"
          depressed
          light
          tabindex="4"
          tile
          type="submit"
          v-text="$t('actions.save')"
        />
      </v-row>
    </v-form>
  </v-col>
</template>

<script>
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import resetRequest from '@/modules/authorisation/api/reset.js';
import { getOrganisationFromUrl } from '@/application/util/url.js';
import { getRateLimitMinutes } from '@/application/api/util/response.js';

export default {
  name: 'PasswordReset',
  components: {
    KTextField,
    KFieldGroup,
  },
  data() {
    return {
      alertType: 'success',
      alertMessage: '',
      isLoading: false,
      isValid: false,
      form: {
        email: '',
        password: '',
        passwordConfirmation: '',
      },
      showPassword: false,
    };
  },
  computed: {
    passwordHint(){
      return this.form.password.length < 10 ? this.$t('authorisation.passwordReset.chooseLongerPassword') : '';
    },
    passwordConfirmationHint(){
      return this.form.password.length < 10 ? this.passwordHint : '';
    },
  },
  created() {
    this.form.email = this.$route.query.email;
  },
  methods: {
    handleRegister() {
      this.$refs.form.validate();

      if (!this.isValid) return;

      this.isLoading = true;
      this.alertType = 'error';
      this.errorMessage = '';

      resetRequest(this.form.email, this.$route.params.token, this.form.password, this.form.passwordConfirmation, getOrganisationFromUrl())
        .then(() => {
          this.$router.push({ name: 'login', params: { passwordResetRedirect: true } });
        })
        .catch((error) => {
          this.alertType = 'error';
          const { response } = error;
          const { status } = response;

          if (status === 429) {
            this.alertMessage = this.$t('errors.429', { minutes: getRateLimitMinutes(response) });
          } else if (status === 400) {
            this.alertMessage = this.$t('authorisation.passwordReset.errorMessage');
          }

          this.$refs.form.validate();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
